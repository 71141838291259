<template>
  <v-container class="not-extended">
    <v-data-table
      :headers="headers"
      :items="policyRange"
      class="elevation-1"
      :loading="loading.table"
      :no-data-text="text.table.empty"
      :loading-text="text.table.loading"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat class="align-end">
          <v-autocomplete
            :value="selectedCompany"
            :items="companies"
            item-text="descripcion"
            hide-details
            dense
            return-object
            :loading="loading.companies"
            :label="text.companySelect.label"
            :placeholder="text.companySelect.placeholder"
            @change="handlerSelectCompany"
          ></v-autocomplete>

          <v-divider class="mx-4" inset vertical></v-divider>

          <v-text-field
            v-model="pagination.filter"
            clearable
            @click:clear="(pagination.filter = ''), handlerSearch()"
            dense
            :label="text.search.label"
            :placeholder="text.search.placeholder"
            class="align-self-center"
            hide-details
            append-icon="mdi-magnify-expand"
            @click:append="handlerSearch()"
            @keyup.enter="handlerSearch()"
          ></v-text-field>

          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="open()"
            :loading="loading.products"
            :disabled="!selectedCompany"
          >
            {{ text.button }}
            <v-icon right>mdi-draw-pen</v-icon>
          </v-btn>
        </v-toolbar>
        <p class="caption grey--text text--darken-1 mb-0 px-4">
          {{ text.companySelect.hint }}
        </p>
      </template>

      <template v-slot:[`item.eliminado`]="{ item }">
        <v-chip
          class="ma-2"
          small
          :color="item.eliminado ? 'grey lighten-2' : 'primary'"
        >
          <v-icon x-small class="mr-1">mdi-checkbox-blank-circle</v-icon>
          {{ item.eliminado ? "Inactivo" : "Activo" }}
        </v-chip>
      </template>

      <template v-slot:[`item.producto.descripcion`]="{ item }">
        <p class="mb-0 font-weight-medium">
          <span
            v-if="
              webServiceBehaviors.includes(
                parseInt(item.compania.comportamiento)
              )
            "
            >WS <v-icon small>mdi-web</v-icon> {{ item.ws.nombre }}</span
          >
          <span v-else>{{ item.producto.descripcion }}</span>
        </p>
      </template>

      <template v-slot:[`item.poliza`]="{ item }">
        <v-chip-group
          v-if="
            parseInt(item.compania.comportamiento) == 3 ||
            parseInt(item.compania.comportamiento) == 4 ||
            (item.poliza.individual.length > 0 &&
              item.producto.tipo_poliza == 'INDIVIDUAL')
          "
          active-class="primary--text"
          column
        >
          <v-chip
            outlined
            v-for="(item, index) in item.poliza.individual"
            :key="index"
          >
            D-{{ item.deducible }} <v-icon x-small>mdi-arrow-right</v-icon>
            {{ item.poliza }}
          </v-chip>
        </v-chip-group>
        <div v-else>
          <v-chip v-show="item.poliza.colectiva !== ''" outlined>
            <v-icon small>mdi-check</v-icon>{{ item.poliza.colectiva }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.certificado`]="{ item }">
        <p class="mb-0">
          {{ item.certificado.desde }}
          <span v-if="item.certificado.hasta !== ''"
            >- {{ item.certificado.hasta }}</span
          >
        </p>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left color="success">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="success"
              small
              text
              fab
              @click="activateItem(item)"
              v-on="on"
              v-bind="attrs"
              :disabled="!item.eliminado"
            >
              <v-icon> mdi-checkbox-marked-circle </v-icon>
            </v-btn>
          </template>
          <span>{{ text.table.activate }} {{ item.producto.descripcion }}</span>
        </v-tooltip>

        <v-tooltip left color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              small
              text
              fab
              @click="editItem(item)"
              :disabled="!selectedCompany"
              v-on="on"
              v-bind="attrs"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <span>{{ text.table.edit }} {{ item.producto.descripcion }}</span>
        </v-tooltip>
        <v-tooltip left color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="error"
              small
              text
              fab
              @click="deleteItemConfirm(item)"
              v-on="on"
              v-bind="attrs"
              :disabled="item.eliminado"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>{{ text.table.remove }} {{ item.producto.descripcion }}.</span>
        </v-tooltip>
      </template>
      <!-- //PAGINACION -->
      <template v-slot:footer>
        <v-container>
          <v-pagination
            circle
            :value="pagination.page"
            :length="pagination.pages"
            :total-visible="7"
            @input="handlerPagination"
            :disabled="loading.table"
          ></v-pagination>
        </v-container>
      </template>
    </v-data-table>

    <!-- MODAL CREAR Y EDITAR -->
    <v-dialog
      v-model="dialog"
      @click:outside="close()"
      max-width="500px"
      :persistent="loading.form"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{
            selected ? text.form.editTitle : text.form.newTitle
          }}</span>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            x-small
            fab
            @click="close()"
            :disabled="loading.form"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <policy-range-form
              v-if="dialog"
              ref="formComponent"
              :formType="
                selected
                  ? parseInt(selected.compania.comportamiento)
                  : selectedCompany
                  ? selectedCompany.comportamiento
                  : 1
              "
              :behaviour="
                selected
                  ? selected.compania.titulo
                  : selectedCompany
                  ? selectedCompany.policyType
                  : ''
              "
              :mainListLoading="loading.products"
              :products="productos"
              :webservices="services"
              :formData="selected"
              @form-show="handlerGetProducts"
            ></policy-range-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="handlerSave()"
            :loading="loading.form"
          >
            {{ selected ? "Actualizar" : "Guardar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- MODAL ELIMINAR -->
    <v-dialog v-model="dialogDelete" max-width="500px" persistent>
      <v-card>
        <v-card-title class="text-h5">{{ text.delete.title }}</v-card-title>
        <v-card-text>
          {{ text.delete.description }}
          <b>{{ selected ? selected.producto.descripcion : "" }}</b
          >?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDelete()"
            :disabled="loading.form"
            >Cancelar</v-btn
          >
          <v-btn
            color="error"
            text
            @click="deleteItem()"
            :loading="loading.form"
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      top
      right
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PolicyRangeForm from "@/components/Forms/policyRange.vue";
export default {
  name: "ProductPolicyRange",
  components: {
    PolicyRangeForm,
  },
  data: () => ({
    search: "",
    loading: {
      table: false,
      companies: false,
      form: false,
      products: false,
    },
    dialog: false,
    dialogDelete: false,
    selected: null,
    selectedCompany: null,
    services: [],
    snackbar: {
      show: false,
      text: "",
      timeout: 5000,
      color: "black",
    },
    pagination: {
      filter: "",
      page: 1,
      limit: 10,
      objects: 0,
      pages: 1,
    },
  }),
  created() {
    this.$store.commit("setPage", { page: 3 });
    this.setup();
  },
  computed: {
    ...mapGetters("NewProduct", ["companies", "productos"]),
    ...mapGetters("Polizas", ["policyRange"]),

    headers() {
      return [
        {
          text: this.text.table.labels.product,
          align: "start",
          sortable: false,
          value: "producto.descripcion",
        },
        {
          text: this.text.table.labels.policy,
          align: "start",
          sortable: false,
          value: "poliza",
        },
        {
          text: this.text.table.labels.certificate,
          align: "center",
          sortable: false,
          value: "certificado",
        },
        {
          text: this.text.table.labels.currentCertificate,
          align: "center",
          sortable: false,
          value: "certificado.actual",
        },
        {
          text: "Estado",
          align: "center",
          sortable: true,
          value: "eliminado",
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ];
    },
    text() {
      return {
        title: "Asignación y distribución de pólizas",
        button: "Agregar póliza",
        form: {
          newTitle: "Nueva asignación de póliza",
          editTitle: "Modificar la póliza",
        },
        delete: {
          title: "Confirmar eliminación",
          description: "Está seguro que desea elminar la póliza de",
        },
        search: {
          label: "Buscar una póliza",
          placeholder: "Buscar por producto, póliza o certificado",
        },
        companySelect: {
          label: "Compañias",
          placeholder: "Seleccione una compañia",
          hint: "Seleccione una compañia para buscar información y crear nuevas pólizas",
        },
        table: {
          labels: {
            product: "Producto",
            policy: "Póliza",
            certificate: "Certificado",
            currentCertificate: "Cert. Actual",
          },
          empty: "Listado vacío",
          loading: "Cargando listado...",
          edit: "Editar la póliza de",
          remove: "Eliminar la póliza de",
          activate: "Activar nuevamente la póliza de",
        },
      };
    },
    webServiceBehaviors() {
      return [5, 6];
    },
  },
  methods: {
    ...mapActions("Integrator", ["getServices"]),
    ...mapActions("NewProduct", [
      "GetCompaniesWithoutLogo",
      "getProductsByCompany",
    ]),
    ...mapActions("Polizas", [
      "REQUEST_POLICY_RANGE",
      "CreatePolicyRange",
      "UpdatePolicyRange",
      "DeletePolicyRange",
      "ActivatePolicyRange",
    ]),

    async setup() {
      this.handlerGetCompanies();
    },
    handlerGetList() {
      this.loading.table = true;
      return new Promise((resolve) => {
        this.REQUEST_POLICY_RANGE(this.pagination)
          .then((response) => this.setPagination(response.pagination))
          .catch((message) => this.setSnackbar(message, "error"))
          .finally(() => {
            this.loading.table = false;
            resolve();
          });
      });
    },
    // PAGINATION METHODS
    setPagination(data) {
      this.pagination.page = data.pagina;
      this.pagination.limit = data.limite;
      this.pagination.pages = data.paginas;
      this.pagination.objects = data.total;
    },
    async handlerPagination(pageNum) {
      this.pagination.page = pageNum;
      await this.handlerGetList();
    },
    async handlerSearch() {
      this.pagination.page = 1;
      await this.handlerGetList();
    },
    // COMPANYS METHODS
    handlerGetCompanies() {
      this.loading.companies = true;
      return new Promise((resolve) => {
        this.GetCompaniesWithoutLogo()
          .catch((message) => this.setSnackbar(message, "error"))
          .finally(() => {
            this.loading.companies = false;
            resolve();
          });
      });
    },
    async handlerSelectCompany(item) {
      this.selectedCompany = item;
      const policyType =
        item.tipo_poliza.length > 0
          ? item.tipo_poliza[0]
          : {
              comportamiento: "",
              titulo: "",
            };
      this.selectedCompany.comportamiento =
        policyType.comportamiento !== ""
          ? parseInt(policyType.comportamiento)
          : 1;
      this.selectedCompany.policyType = policyType.titulo;
      this.pagination.company = item.id;
      await this.handlerGetList();
    },
    // ----
    handlerGetProducts() {
      return new Promise((resolve, reject) => {
        if (this.selectedCompany) {
          if (
            this.selected &&
            this.selected.compania.id !== this.selectedCompany.id
          ) {
            reject("Esta póliza no concuerda con la compañia seleccionada");
          }
          this.getProductsByCompany(this.selectedCompany.id)
            .then(() => resolve("ok"))
            .catch((message) => {
              this.setSnackbar(message, "warning");
              reject(message);
            });
        } else {
          reject("No se ha seleccionado una compañia");
        }
      });
    },
    handlerGetServices() {
      return new Promise((resolve, reject) => {
        this.getServices()
          .then((data) => {
            this.services = data.servicios;
            resolve("ok");
          })
          .catch(() => reject("No se ha logrado recuperar los servicios"));
      });
    },
    open() {
      this.loading.products = true;
      const behaviour = this.selected
        ? parseInt(this.selected.compania.comportamiento)
        : this.selectedCompany
        ? this.selectedCompany.comportamiento
        : 1;
      const METHOD = this.webServiceBehaviors.includes(behaviour)
        ? this.handlerGetServices
        : this.handlerGetProducts;
      METHOD()
        .then(() => (this.dialog = true))
        .catch((message) => this.setSnackbar(message, "warning"))
        .finally(() => (this.loading.products = false));
    },
    handlerSave() {
      if (this.selected) {
        this.update();
      } else {
        this.save();
      }
    },
    save() {
      this.loading.form = true;
      let formData = this.$refs.formComponent.form;
      formData.compania = {
        id: this.selectedCompany.id,
        comportamiento: this.selectedCompany.comportamiento,
        titulo: this.selectedCompany.policyType,
      };
      this.CreatePolicyRange(formData)
        .then((message) => {
          this.setSnackbar(message, "success");
          this.close();
        })
        .catch((message) => this.setSnackbar(message, "error"))
        .finally(() => (this.loading.form = false));
    },
    update() {
      this.loading.form = true;
      let formData = this.$refs.formComponent.form;
      formData.compania = {
        id: this.selected.compania.id,
        comportamiento: this.selected.compania.comportamiento,
        titulo: this.selected.compania.titulo,
      };
      const payload = {
        id: this.selected.asignacion_poliza,
        data: formData,
      };
      this.UpdatePolicyRange(payload)
        .then((message) => {
          this.setSnackbar(message, "success");
          this.close();
        })
        .catch((message) => this.setSnackbar(message, "error"))
        .finally(() => (this.loading.form = false));
    },
    activateItem(item) {
      this.selected = item;
      this.loading.table = true;
      this.ActivatePolicyRange(item.asignacion_poliza)
        .then(async (message) => {
          await this.handlerGetList();
          this.setSnackbar(message, "success");
        })
        .catch((message) => this.setSnackbar(message, "error"));
    },
    deleteItem() {
      this.loading.form = true;
      this.DeletePolicyRange(this.selected.asignacion_poliza)
        .then(async (message) => {
          await this.handlerGetList();
          this.setSnackbar(message, "success");
          this.closeDelete();
        })
        .catch((message) => this.setSnackbar(message, "error"))
        .finally(() => (this.loading.form = false));
    },
    setSnackbar(text = "", color = "black") {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
    close() {
      this.dialog = false;
      this.selected = null;
    },
    editItem(item) {
      this.selected = item;
      this.open();
    },
    deleteItemConfirm(item) {
      this.selected = item;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.selected = null;
    },
  },
};
</script>

<style></style>
