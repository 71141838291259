<template>
  <v-form>
    <p class="font-weight-medium">{{ formType }}) {{ behaviour }}</p>
    <v-autocomplete
      v-if="show.productField"
      v-model="form.producto.id"
      :items="products"
      dense
      outlined
      :loading="mainListLoading"
      item-text="nombre_comercial"
      item-value="id_producto"
      label="Producto"
      placeholder="Seleccione un producto"
      @change="handlerSelectProduct"
      :no-data-text="'No se han encontrado productos'"
    ></v-autocomplete>

    <v-autocomplete
      v-if="show.webService"
      v-model="form.ws"
      :items="webservices"
      :loading="mainListLoading"
      dense
      outlined
      label="WS"
      item-text="nombre"
      :no-data-text="'No se han encontrado servicios'"
      return-object
    ></v-autocomplete>

    <v-fade-transition>
      <div v-if="show.policyTypeText">
        <span class="caption">Tipo de poliza:</span>
        <p class="secondary text-center font-weight-medium subtitle-1 pa-1">
          {{ policyType }}
        </p>
      </div>
    </v-fade-transition>

    <v-divider class="mb-6"></v-divider>

    <v-fade-transition>
      <v-text-field
        v-if="show.policyInput"
        label="Póliza"
        dense
        outlined
        v-model="form.poliza.colectiva"
      ></v-text-field>
    </v-fade-transition>

    <v-list v-if="show.policyList">
      <v-fade-transition group mode="out-in">
        <v-list-item
          :key="`item-${i}`"
          v-for="(item, i) in form.poliza.individual"
        >
          <p class="mb-0 px-3">{{ item.decripcion }}</p>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="item.poliza"
            :label="`Póliza D-${item.deducible}`"
            style="max-width: 150px"
            dense
            outlined
            hide-details
          ></v-text-field>
        </v-list-item>
      </v-fade-transition>
    </v-list>

    <p
      class="caption"
      v-if="
        (!show.policyList && !show.webService && !show.policyInput) ||
        (form.poliza.individual.length <= 0 &&
          !show.webService &&
          !show.policyInput)
      "
    >
      <span v-if="form.producto.id !== ''" class="error--text"
        >No se encontraron deducibles para el producto seleccionado</span
      >
      <span v-else
        ><v-icon left color="info">mdi-information</v-icon> Seleccione un
        producto para ver los deducibles y sus pólizas.</span
      >
    </p>

    <v-divider class="mb-6" v-if="!show.webService"></v-divider>

    <v-fade-transition>
      <div v-if="!show.webService">
        <p class="caption font-weight-bold">Nro. Certificado</p>
        <v-row no-gutters>
          <v-col cols="6" class="pr-2" v-if="show.certificateFromField">
            <v-text-field
              dense
              outlined
              label="Desde"
              v-model="form.certificado.desde"
            ></v-text-field>
          </v-col>
          <v-col cols="6" v-if="show.certificateToField" class="pl-2">
            <v-text-field
              dense
              outlined
              label="Hasta"
              v-model="form.certificado.hasta"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-fade-transition>
  </v-form>
</template>

<script>
export default {
  name: "PolicyRangeForm",
  props: {
    formData: {
      type: Object,
      default: null,
    },
    formType: {
      type: Number,
      default: 1,
    },
    behaviour: {
      type: String,
      default: "",
    },
    products: {
      type: Array,
      default: () => [],
    },
    webservices: {
      type: Array,
      default: () => [],
    },
    mainListLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form: {
      ws: null,
      producto: {
        id: "",
        descripcion: "",
        tipo_poliza: "",
      },
      poliza: {
        colectiva: "",
        individual: [],
      },
      certificado: {
        desde: "",
        hasta: "",
        actual: "",
      },
    },
    policyType: "",
  }),
  created() {
    // this.$emit("form-show");
    if (this.formData) {
      this.setupForm();
    }
  },
  computed: {
    show() {
      return {
        productField:
          this.formType === 1 ||
          this.formType === 2 ||
          this.formType === 3 ||
          this.formType === 4,
        policyInput:
          this.formType === 1 ||
          (this.formType === 2 &&
            this.form.producto.tipo_poliza !== "" &&
            this.form.producto.tipo_poliza === "COLECTIVA") ||
          this.formType === 6,
        policyTypeText: this.formType === 2 && this.policyType !== "",
        policyList:
          (this.formType === 2 &&
            this.form.producto.tipo_poliza !== "" &&
            this.form.producto.tipo_poliza === "INDIVIDUAL") ||
          this.formType === 3 ||
          this.formType === 4,
        certificateFromField:
          this.formType === 1 ||
          this.formType === 2 ||
          this.formType === 3 ||
          this.formType === 4,
        certificateToField:
          this.formType === 2 || this.formType === 3 || this.formType === 4,
        webService: this.formType === 5 || this.formType === 6,
      };
    },
  },
  methods: {
    findProductById(productId) {
      return this.products.find((e) => e.id_producto === productId);
    },
    setupForm() {
      // Rellenar campos de producto
      let policyProduct = this.findProductById(this.formData.producto.id);
      if (policyProduct) {
        this.form.producto.id = policyProduct.id_producto;
        this.handlerSelectProduct(policyProduct.id_producto);
      }
      // Rellenar campos de polizas
      this.form.poliza.colectiva = this.formData.poliza.colectiva || "";
      this.formData.poliza.individual.forEach((element) => {
        let item = this.form.poliza.individual.find((e) => e.id === element.id);
        if (item) {
          item.poliza = element.poliza;
        }
      });
      // Rellenar campos de certificado
      this.form.certificado.desde = this.formData.certificado.desde || "";
      this.form.certificado.hasta = this.formData.certificado.hasta || "";
      // this.form.certificado.actual = this.formData.certificado.actual || ""
      // Rellenar campos de Webservice
      this.form.ws = this.formData.ws;
    },
    handlerSelectProduct(itemId) {
      this.resetFields();
      let item = this.findProductById(itemId);
      this.form.producto.descripcion = item.nombre_comercial;
      this.form.producto.tipo_poliza = item.poliza.poliza;
      this.policyType = item.poliza.poliza_descripcion;
      item.planes.forEach((element) => {
        this.form.poliza.individual.push({
          id: element.id,
          deducible: element.deducible,
          decripcion: element.plan_decripcion,
          poliza: "",
        });
      });
    },
    resetFields() {
      this.form.poliza.individual = [];
    },
  },
};
</script>

<style></style>
